import { CharacterPickerConfig } from './CharacterPicker';
import { GameLoopConfig } from './GameLoop';

interface LevelSettings {
  gameLoopConfig: GameLoopConfig,
  characterPickerConfig: CharacterPickerConfig
}

class LevelSettingMapper {
  public getSettingsForLevel = (level: number): LevelSettings => {
    switch (level) {
      case 0:
        return {
          gameLoopConfig: {
            characterInterval: 1000,
            betweenInterval: 1000,
            imageInterval: 70,
          },
          characterPickerConfig: {
            minDistanceBetweenRepeat: 0,
            fixedTargetLetter: 'x',
          },
        };
      case 1:
        return {
          gameLoopConfig: {
            characterInterval: 1000,
            betweenInterval: 1000,
            imageInterval: 70,
          },
          characterPickerConfig: {
            minDistanceBetweenRepeat: 0,
            fixedTargetLetter: null,
          },
        };
      case 2:
        return {
          gameLoopConfig: {
            characterInterval: 1000,
            betweenInterval: 1000,
            imageInterval: 70,
          },
          characterPickerConfig: {
            minDistanceBetweenRepeat: 1,
            fixedTargetLetter: null,
          },
        };
      case 3:
        return {
          gameLoopConfig: {
            characterInterval: 1000,
            betweenInterval: 1000,
            imageInterval: 70,
          },
          characterPickerConfig: {
            minDistanceBetweenRepeat: 2,
            fixedTargetLetter: null,
          },
        };
      case 4:
        return {
          gameLoopConfig: {
            characterInterval: 1000,
            betweenInterval: 1000,
            imageInterval: 70,
          },
          characterPickerConfig: {
            minDistanceBetweenRepeat: 3,
            fixedTargetLetter: null,
          },
        };
      case 5:
        return {
          gameLoopConfig: {
            characterInterval: 1000,
            betweenInterval: 1000,
            imageInterval: 70,
          },
          characterPickerConfig: {
            minDistanceBetweenRepeat: 4,
            fixedTargetLetter: null,
          },
        };
      case 6:
        return {
          gameLoopConfig: {
            characterInterval: 1000,
            betweenInterval: 1000,
            imageInterval: 70,
          },
          characterPickerConfig: {
            minDistanceBetweenRepeat: 5,
            fixedTargetLetter: null,
          },
        };
      case 7:
        return {
          gameLoopConfig: {
            characterInterval: 1000,
            betweenInterval: 1000,
            imageInterval: 70,
          },
          characterPickerConfig: {
            minDistanceBetweenRepeat: 6,
            fixedTargetLetter: null,
          },
        };
      case 8:
        return {
          gameLoopConfig: {
            characterInterval: 1000,
            betweenInterval: 1000,
            imageInterval: 70,
          },
          characterPickerConfig: {
            minDistanceBetweenRepeat: 7,
            fixedTargetLetter: null,
          },
        };
      case 9:
        return {
          gameLoopConfig: {
            characterInterval: 1000,
            betweenInterval: 1000,
            imageInterval: 70,
          },
          characterPickerConfig: {
            minDistanceBetweenRepeat: 8,
            fixedTargetLetter: null,
          },
        };
      case 10:
        return {
          gameLoopConfig: {
            characterInterval: 1000,
            betweenInterval: 1000,
            imageInterval: 70,
          },
          characterPickerConfig: {
            minDistanceBetweenRepeat: 9,
            fixedTargetLetter: null,
          },
        };
      case 11:
        return {
          gameLoopConfig: {
            characterInterval: 1000,
            betweenInterval: 1000,
            imageInterval: 70,
          },
          characterPickerConfig: {
            minDistanceBetweenRepeat: 10,
            fixedTargetLetter: null,
          },
        };
      default:
        throw new Error(`level ${level} not found`);
    }
  }
}

export default LevelSettingMapper;
