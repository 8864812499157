<template>
  <div class="bg-gradient-to-b from-blue-500  to-blue-700 min-h-screen">
    <top-nav />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Footer from './components/Footer.vue';
import TopNav from './components/TopNav.vue';

export default {
  name: 'App',
  components: {
    TopNav,
    Footer,
  },
};
</script>

<style scoped>
</style>
