<template>
  <img
    :src="require('@/assets/check_circle_FILL0_wght400_GRAD0_opsz48.svg')"
    alt=""
  >
</template>

<script>

export default {
  name: 'CheckMarkIcon',
  components: {},
};
</script>

<style>
</style>
