class ScoreTracker {
  numCorrectInputs = 0;

  numIncorrectInputs = 0;

  numMissedInputs = 0;

  numLettersTotal = 0;

  public correctInput = (): void => {
    this.numCorrectInputs++;
  }

  public incorrectInput = (): void => {
    this.numIncorrectInputs++;
  }

  public missedInput = (): void => {
    this.numMissedInputs++;
  }

  public newLetter = (): void => {
    this.numLettersTotal++;
  }

  public getTotalCorrect = (): number => this.numCorrectInputs

  public getTotalIncorrect = (): number => this.numIncorrectInputs

  public getTotalMissed = (): number => this.numMissedInputs

  public getTotalLetters = (): number => this.numLettersTotal

  private getPossibleTotalCorrect = (): number => this.numCorrectInputs + this.numMissedInputs

  public getPercentage = (): number => Math.round((1 - ((this.numIncorrectInputs + this.numMissedInputs)) / this.getPossibleTotalCorrect()) * 100)
}

export default ScoreTracker;
