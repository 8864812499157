<template>
  <img
    :src="require('@/assets/settings_FILL0_wght400_GRAD0_opsz48.svg')"
    alt=""
  >
</template>

<script>

export default {
  name: 'Settings',
  components: {},
};
</script>

<style>
</style>
