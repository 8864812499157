class AnxietyImageCollection {
  public static getUrls = (): string[] => [
    '/images/subliminal/anxiety/caju-gomes-HeVI61y6NzM-unsplash.jpg',
    '/images/subliminal/anxiety/chaozzy-lin-o88wlqg87UY-unsplash.jpg',
    '/images/subliminal/anxiety/chaozzy-lin-z2x0Mhfn10A-unsplash.jpg',
    '/images/subliminal/anxiety/diane-picchiottino-6PDECHb3kRE-unsplash.jpg',
    '/images/subliminal/anxiety/engin-akyurt-EVym5_vdbFQ-unsplash.jpg',
    '/images/subliminal/anxiety/engin-akyurt-SMI1NhNXszc-unsplash.jpg',
    '/images/subliminal/anxiety/engin-akyurt-uUM_Yv16oFs-unsplash.jpg',
    '/images/subliminal/anxiety/fernando-cferdophotography-5CnrCrmwwIU-unsplash.jpg',
    '/images/subliminal/anxiety/jack-lucas-smith-Zxq0dvmRyIo-unsplash.jpg',
    '/images/subliminal/anxiety/kat-j-7I1wrtRz5QQ-unsplash.jpg',
    '/images/subliminal/anxiety/kat-j-NPmR0RblyhQ-unsplash.jpg',
    '/images/subliminal/anxiety/kayla-koss-YshK1sSeQjw-unsplash.jpg',
    '/images/subliminal/anxiety/kyle-cleveland-bQLLln0-jUo-unsplash.jpg',
    '/images/subliminal/anxiety/luiz-rogerio-nunes-gkJYH0FLtt0-unsplash.jpg',
    '/images/subliminal/anxiety/meghan-hessler-2fyVQuQLh9k-unsplash.jpg',
    '/images/subliminal/anxiety/sander-sammy--UVPQ6FpBAA-unsplash.jpg',
    '/images/subliminal/anxiety/shane-tRVJtp3jB-A-unsplash.jpg',
    '/images/subliminal/anxiety/taylor-deas-melesh-fkaQ-cqU4Uo-unsplash.jpg',
    '/images/subliminal/anxiety/uday-mittal-bwKtz4YVtmA-unsplash.jpg',
    '/images/subliminal/anxiety/usman-yousaf-8dvyPDYa35Q-unsplash.jpg',
  ]
}

export default AnxietyImageCollection;
