import { Action } from '../ActionList';

class DelayAction implements Action {
  isFinished = false;

  delay: number;

  currentTime = 0;

  constructor(delay: number) {
    this.delay = delay;
  }

  update = (dt: number): void => {
    this.currentTime += dt;
    if (this.currentTime >= this.delay) {
      this.isFinished = true;
    }
  }

  reset = (): void => {
    this.currentTime = 0;
    this.isFinished = false;
  }
}

export default DelayAction;
