class AppetiteImageCollection {
  public static getUrls = (): string[] => [
    '/images/subliminal/appetite/aigars-peda-a_iKAg00LN4-unsplash.jpg',
    '/images/subliminal/appetite/alex-munsell-auIbTAcSH6E-unsplash.jpg',
    '/images/subliminal/appetite/alex-munsell-Yr4n8O_3UPc-unsplash.jpg',
    '/images/subliminal/appetite/amirali-mirhashemian-sc5sTPMrVfk-unsplash.jpg',
    '/images/subliminal/appetite/anna-tukhfatullina-food-photographer-stylist-Mzy-OjtCI70-unsplash.jpg',
    '/images/subliminal/appetite/casey-lee-awj7sRviVXo-unsplash.jpg',
    '/images/subliminal/appetite/chad-montano-eeqbbemH9-c-unsplash.jpg',
    '/images/subliminal/appetite/chad-montano--GFCYhoRe48-unsplash.jpg',
    '/images/subliminal/appetite/chad-montano-lP5MCM6nZ5A-unsplash.jpg',
    '/images/subliminal/appetite/chad-montano-MqT0asuoIcU-unsplash.jpg',
    '/images/subliminal/appetite/clarissa-carbungco-uy9DJw9e_vs-unsplash.jpg',
    '/images/subliminal/appetite/eaters-collective-12eHC6FxPyg-unsplash.jpg',
    '/images/subliminal/appetite/eaters-collective-pLKgCsBOiw4-unsplash.jpg',
    '/images/subliminal/appetite/eiliv-sonas-aceron-pu6b4yIlQF4-unsplash.jpg',
    '/images/subliminal/appetite/emile-mbunzama-cLpdEA23Z44-unsplash.jpg',
    '/images/subliminal/appetite/emiliano-vittoriosi-OFismyezPnY-unsplash.jpg',
    '/images/subliminal/appetite/joseph-gonzalez-zcUgjyqEwe8-unsplash.jpg',
    '/images/subliminal/appetite/luisana-zerpa-MJPr6nOdppw-unsplash.jpg',
    '/images/subliminal/appetite/monika-grabkowska-_y6A9bhILkM-unsplash.jpg',
    '/images/subliminal/appetite/victoria-shes-UC0HZdUitWY-unsplash.jpg',
  ]
}

export default AppetiteImageCollection;
