<template>
  <div
    class="bg-white inline-block px-6 py-4 rounded text-left md:text-center max-w-3xl mx-6 -mt-20 md:mt-0"
  >
    <div class="text-blue-700 text-2xl font-bold ">
      Level Complete
    </div>
    <div class="block md:hidden my-4">
      <div class="text-xl">
        <span class="font-bold text-gray-700">
          Correct
        </span>
        <span class="text-green-700 font-bold">
          {{ totalCorrect }}
        </span>
      </div>
      <div class="text-xl">
        <span class="font-bold text-gray-700">
          False Positive
        </span>
        <span class="text-red-700 font-bold">
          {{ totalFalsePositive }}
        </span>
      </div>
      <div class="text-xl">
        <span class="font-bold text-gray-700">
          Missed
        </span>
        <span class="text-red-700 font-bold">
          {{ totalMissed }}
        </span>
      </div>
      <div class="text-xl">
        <span class="font-bold text-gray-700">
          Total Letters
        </span>
        <span class="text-blue-700 font-bold">
          {{ totalLetters }}
        </span>
      </div>
      <div class="text-xl">
        <span class="font-bold text-gray-700">
          Percentage
        </span>
        <span class="text-green-700 font-bold">
          {{ percentage }}%
        </span>
      </div>
    </div>
    <div class="divide-x-2 my-6 text-xl hidden md:flex">
      <div class="pr-2">
        <div class="font-bold text-gray-700">
          Correct
        </div>
        <div class="text-green-700 font-bold">
          {{ totalCorrect }}
        </div>
      </div>
      <div class="px-2">
        <div class="font-bold text-gray-700">
          False Positive
        </div>
        <div class="text-red-700 font-bold">
          {{ totalFalsePositive }}
        </div>
      </div>
      <div class="px-2">
        <div class="font-bold text-gray-700">
          Missed
        </div>
        <div class="text-red-700 font-bold">
          {{ totalMissed }}
        </div>
      </div>
      <div class="px-2">
        <div class="font-bold text-gray-700">
          Total Letters
        </div>
        <div class="text-blue-700 font-bold">
          {{ totalLetters }}
        </div>
      </div>
      <div class="pl-2">
        <div class="font-bold text-gray-700">
          Percentage
        </div>
        <div class="text-green-700 font-bold">
          {{ percentage }}%
        </div>
      </div>
    </div>

    <div
      v-if="percentage <= 80"
      class="mb-8 pt-2"
    >
      <lock-icon class="inline-block h-6" />
      <span class="align-middle ml-2 text-xl">
        Score 80% to unlock next level
      </span>
    </div>
    <div
      v-else
      class="mb-8 pt-2"
    >
      <unlock-icon class="inline-block h-6" />
      <span class="align-middle ml-2 text-xl">
        Next Level Unlocked
      </span>
    </div>
    <div class="block md:flex md:justify-center text-xl">
      <button
        class="text-center w-full md:w-fit block md:inline-block bg-blue-700 text-white rounded font-thin px-4 py-1 mr-6"
        @click="reload()"
      >
        Replay
      </button>
      <router-link
        class="text-center mt-4 md:mt-0 w-full md:w-fit block md:inline-block bg-blue-700 text-white rounded font-thin px-4 py-1"
        to="../level-select"
      >
        Level Select
      </router-link>
    </div>
  </div>
</template>

<script>
import LockIcon from './Icons/LockIcon.vue';
import UnlockIcon from './Icons/UnlockIcon.vue';

export default {
  name: 'LevelEndPanel',
  components: {
    LockIcon,
    UnlockIcon,
  },
  props: {
    totalCorrect: {
      type: Number,
      default: null,
    },
    totalFalsePositive: {
      type: Number,
      default: null,
    },
    totalMissed: {
      type: Number,
      default: null,
    },
    percentage: {
      type: Number,
      default: null,
    },
    totalLetters: {
      type: Number,
      default: null,
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const reload = () => {
      window.location.reload();
    };
    return {
      reload,
    };
  },
};
</script>

<style
    CYAHeadLogo>
</style>
