import { Action } from '../ActionList';

class RestAction implements Action {
  isFinished = false;

  duration: number;

  restStart: () => void;

  restEnd: () => void;

  currentTime = 0;

  constructor(
    duration: number,
    restStart: () => void,
    restEnd: () => void,
  ) {
    this.duration = duration;
    this.restStart = restStart;
    this.restEnd = restEnd;
  }

  update = (dt: number): void => {
    this.currentTime += dt;
    this.restStart();
    if (this.currentTime >= this.duration) {
      this.restEnd();
      this.isFinished = true;
    }
  }

  reset = (): void => {
    this.currentTime = 0;
    this.isFinished = false;
  }
}

export default RestAction;
