
import { reactive } from '@vue/runtime-dom';
import { useRoute } from 'vue-router';
import LevelSelector from '../components/LevelSelector.vue';
import { db, LevelCompletion } from '../db';

export default {
  name: 'LevelSelect',
  components: { LevelSelector },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const route: any = useRoute();
    const { category } = route.params;
    const levelCompletionPercentages = reactive({
      value: [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
      ],
    });

    const getLevelCompletions = async (): Promise<LevelCompletion[] | undefined> => {
      try {
        return await db.levelCompletions
          .where('category')
          .equals(category)
          .reverse()
          .sortBy('level');
      } catch (error) {
        return undefined;
      }
    };

    getLevelCompletions().then((completions): void => {
      if (!completions || completions.length < 1) {
        return;
      }
      completions.forEach((completion: LevelCompletion) => {
        if (levelCompletionPercentages.value[completion.level] < completion.percentage) {
          levelCompletionPercentages.value[completion.level] = completion.percentage;
        }
      });
    });

    return {
      levelCompletionPercentages,
    };
  },
};
