<template>
  <router-link
    class="bg-white rounded py-4 px-4 relative hover:bg-gray-100 cursor-pointer"
    :to="'/categories/' + categoryName + '/level-select'"
  >
    <div
      class="text-xl font-bold md:flex md:justify-between text-center md:text-left"
      :class="'text-' + foregroundColor"
    >
      <LevelCategorySelectorIcon
        class="block md:hidden"
        :background-color="backgroundColor"
        :icon="iconName"
      />
      <div>
        {{ categoryName }}
      </div>
      <LevelCategorySelectorIcon
        class="hidden md:inline-block"
        :background-color="backgroundColor"
        :icon="iconName"
      />
    </div>
    <div class="text-gray-700 text-xl mt-4 text-center md:text-left">
      <strong>High Score:</strong> {{ progress }}% On&nbsp;Level&nbsp;{{ levelName }}
    </div>
    <loading-bar
      :progress="progress"
      :background-color="backgroundColor"
      :foreground-color="foregroundColor"
      class="mt-2"
    />
  </router-link>
</template>

<script>
import LevelCategorySelectorIcon from './Icons/LevelCategorySelectorIcon.vue';
import LoadingBar from './LoadingBar.vue';

export default {
  name: 'LevelCategorySelector',
  components: {
    LoadingBar,
    LevelCategorySelectorIcon,
  },
  props: {
    levelName: {
      type: Number,
      default: null,
    },
    categoryName: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
    progress: {
      type: Number,
      default: 0,
    },
    backgroundColor: {
      type: String,
      default: 'blue-700',
    },
    foregroundColor: {
      type: String,
      default: 'blue-200',
    },
  },
};
</script>

<style>
</style>
