<template>
  <div
    class="center-vertical w-full px-6"
  >
    <div
      class="bg-white px-6 text-center py-4 rounded max-w-xl mx-auto -mt-32"
    >
      <lock-icon class="mx-auto" />
      <div class="text-gray-700 text-2xl">
        It looks like you haven't unlocked this level yet
      </div>
      <router-link
        class="text-center mt-4 w-full block bg-blue-700 text-white rounded font-thin px-4 py-1"
        to="../level-select"
      >
        Level Select
      </router-link>
    </div>
  </div>
</template>

<script>
import LockIcon from './Icons/LockIcon.vue';

export default {
  name: 'LevelLockedWarning',
  components: {
    LockIcon,
  },
  props: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
