interface CharacterPickerConfig {
  minDistanceBetweenRepeat: number,
  fixedTargetLetter: string | null
}

class CharacterPicker {
  private availableCharacters: string[] = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

  private unavailableCharacters: string[] = [];

  private targetCharacter = '';

  private isMatch = false;

  private fixedTargetLetter: string | null;

  private minDistanceBetweenRepeat = 0;

  constructor(
    config: CharacterPickerConfig,
  ) {
    this.minDistanceBetweenRepeat = config.minDistanceBetweenRepeat;
    this.fixedTargetLetter = config.fixedTargetLetter;
  }

  public getNextLetter = (): string => {
    const letter = this.getRandomAvailable(0.20); // 20% chance of showing target character
    this.makeUnavailable(letter);
    this.setIsMatch(letter === this.getTargetLetter());
    if (this.unavailableCharacters.length > this.minDistanceBetweenRepeat) {
      this.makeOldestCharacterAvailable();
    }

    return letter;
  }

  public getIsMatch(): boolean {
    return this.isMatch;
  }

  public reset(): void {
    this.availableCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    this.unavailableCharacters = [];
    this.targetCharacter = '';
    this.isMatch = false;
  }

  private getRandomAvailable = (targetCharacterChance: number): string => {
    const shouldShowTarget = Math.random() < targetCharacterChance;
    const targetIsAvailable = this.availableCharacters.findIndex((letter) => letter === this.getTargetLetter()) > 0;
    if (
      shouldShowTarget
      && targetIsAvailable
      && this.getTargetLetter() !== ''
    ) {
      return this.getTargetLetter();
    }
    const availableNotTarget = this.availableCharacters
      .filter((character) => character !== this.getTargetLetter());
    return availableNotTarget[Math.floor(Math.random() * availableNotTarget.length)];
  }

  private makeOldestCharacterAvailable = (): void => {
    [this.targetCharacter] = this.unavailableCharacters;
    this.makeAvailable(this.unavailableCharacters[0]);
  }

  private makeUnavailable = (characterToMakeUnvailable: string): void => {
    this.availableCharacters = this.availableCharacters
      .filter((character) => character !== characterToMakeUnvailable);
    this.unavailableCharacters.push(characterToMakeUnvailable);
  }

  private makeAvailable = (characterToMakeAvailable: string): void => {
    this.unavailableCharacters = this.unavailableCharacters
      .filter((character) => character !== characterToMakeAvailable);
    this.availableCharacters.push(characterToMakeAvailable);
  }

  private setIsMatch(isMatch: boolean): void {
    this.isMatch = isMatch;
  }

  private getTargetLetter(): string {
    return this.fixedTargetLetter ? this.fixedTargetLetter : this.targetCharacter;
  }
}

export { CharacterPicker };
export type { CharacterPickerConfig };
