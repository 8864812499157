<template>
  <div class="border-t-2 mt-1">
    <div
      class="md:mx-auto mt-36 lg:mt-48 mx-6"
    >
      <div
        class="
        text-white text-2xl text-center
        font-bold
        px-6
        md:px-32
        lg:px-48
        xl:px-96
      "
      >
        Loading Images
      </div>
      <div class="relative mx-auto max-w-lg mt-12">
        <div class="text-white text-2xl text-center my-2">
          {{ Math.round(totalLoaded / totalImages* 100) + "%" }}
        </div>
        <loading-bar
          foreground-color="blue-700"
          background-color="white"
          :progress="(totalLoaded / totalImages) * 100"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingBar from './LoadingBar.vue';

export default {
  name: 'GameLoader',
  components: {
    LoadingBar,
  },
  props: {
    totalLoaded: {
      type: Number,
      default: 0,
    },
    totalImages: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
