
import { ref } from 'vue';

export default {
  name: 'CYAHeadLogo',
  components: {},
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const rotation = ref(0);
    setInterval(() => {
      rotation.value += 0.05;
      if (rotation.value > 360) {
        rotation.value = 0;
      }
    });
    return {
      rotation,
    };
  },
};
