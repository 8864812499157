
import { db, LevelCompletion } from '@/db';
import { reactive } from 'vue';
import dayJs from 'dayjs';

export default {
  name: 'LevelHistory',
  components: { },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const levelCompletions: {value:LevelCompletion[]} = reactive({ value: [] });
    const getLevelCompletions = async (): Promise<LevelCompletion[] | undefined> => {
      try {
        return await db.levelCompletions
          .reverse()
          .sortBy('completedAt');
      } catch (error) {
        return undefined;
      }
    };

    getLevelCompletions().then((completions): void => {
      if (!completions || completions.length < 1) {
        return;
      }
      levelCompletions.value = completions;
    //   completions.forEach((completion: LevelCompletion) => {
    //     console.log(completion);
    //   });
    });

    const formatDateTime = (time: string): string => dayJs(time).format('DD/MM/YYYY HH:mm');

    return {
      levelCompletions,
      formatDateTime,
    };
  },
};
