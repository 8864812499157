class AddictionImageCollection {
  public static getUrls = (): string[] => [
    '/images/subliminal/addiction/andrea-dibitonto-CFu7TroYt3o-unsplash.jpg',
    '/images/subliminal/addiction/andres-siimon-ryBnRg4c3L0-unsplash.jpg',
    '/images/subliminal/addiction/arun-anoop-a_cXH1Mprho-unsplash.jpg',
    '/images/subliminal/addiction/daniel-monteiro-4KZ0iCFEG78-unsplash.jpg',
    '/images/subliminal/addiction/donny-jiang-KFTPuUsIFME-unsplash.jpg',
    '/images/subliminal/addiction/dylan-fout-5xVBBEp0EWo-unsplash.jpg',
    '/images/subliminal/addiction/el-swaggy-UL_2e9DNe48-unsplash.jpg',
    '/images/subliminal/addiction/giorgio-trovato-mVCIG2QHLyI-unsplash.jpg',
    '/images/subliminal/addiction/idin-ebrahimi-re8vcdP3aOk-unsplash.jpg',
    '/images/subliminal/addiction/le-tit-Xo1OlLe81t8-unsplash.jpg',
    '/images/subliminal/addiction/lilartsy-whrscqDO0O4-unsplash.jpg',
    '/images/subliminal/addiction/luka-malic-X6ZsIj6V8Ew-unsplash.jpg',
    '/images/subliminal/addiction/markus-spiske-wcAOi6t0qaA-unsplash.jpg',
    '/images/subliminal/addiction/muhammad-abdullah--g0kkqcX1S4-unsplash.jpg',
    '/images/subliminal/addiction/obby-rh-BPm5DMSop8A-unsplash.jpg',
    '/images/subliminal/addiction/pawel-czerwinski-7USMFYqt1NI-unsplash.jpg',
    '/images/subliminal/addiction/philippe-goulet-zg9dfG9IHL0-unsplash.jpg',
    '/images/subliminal/addiction/reza-mehrad-wQv94mB3TcY-unsplash.jpg',
    '/images/subliminal/addiction/shahin-khalaji-qTMRoHOHu0U-unsplash.jpg',
    '/images/subliminal/addiction/uitbundig-JfeXV_PNUJ8-unsplash.jpg',
  ]
}

export default AddictionImageCollection;
