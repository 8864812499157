<template>
  <div
    ref="topNav"
    class="w-full px-6 py-2 fixed"
  >
    <div class="flex justify-between items-center">
      <router-link
        to="/"
        class="font-bold text-white text-2xl "
      >
        CurbYourAddiction
      </router-link>
      <a
        href="https://www.drsamanthabrooks.com/news-resources"
        target="_blank"
        class="text-white text-2xl hover:font-bold hidden md:block"
      >
        about
      </a>
      <a
        href="https://www.drsamanthabrooks.com/news-resources"
        target="_blank"
        class="text-white text-2xl hover:font-bold block md:hidden"
      >
        <info-icon class="h-7" />
      </a>
    </div>
  </div>
  <div
    ref="headerPadding"
    style="pointer-events: none;"
    class="w-full sticky"
  />
</template>

<script>
import { onMounted, ref } from 'vue';
import InfoIcon from './Icons/InfoIcon.vue';

export default {
  name: 'TopNav',
  components: {
    InfoIcon,
  },
  props: {
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const headerPadding = ref();
    const topNav = ref();
    const setTopPadding = () => {
      headerPadding.value.style.setProperty('padding-top', `${topNav.value.offsetHeight}px`);
    };

    window.addEventListener('resize', (event) => {
      if (topNav.value && headerPadding.value) {
        setTopPadding();
      }
    }, true);
    onMounted(() => {
      setTopPadding();
    });
    return {
      headerPadding,
      topNav,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
