class InputDetector {
  inputHandler: () => void;

  constructor() {
    if (isTouchDevice()) {
      document.addEventListener('touchstart', () => {
        this.inputHandler();
      });
    } else {
      document.addEventListener('mousedown', () => {
        this.inputHandler();
      });
    }

    document.addEventListener('keydown', (event) => {
      if (event.key === ' ') {
        this.inputHandler();
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.inputHandler = () => {};
  }

  setHandler(handleInput: () => void): void {
    this.inputHandler = handleInput;
  }
}

const isTouchDevice = (): boolean => (('ontouchstart' in window)
  || (navigator.maxTouchPoints > 0)
  || (navigator.msMaxTouchPoints > 0));

export default InputDetector;
