<template>
  <div class="fixed bottom-0 w-full px-6 py-4 bg-blue-700 pt-4">
    <div class="text-center text-white">
      <span class="mx-2">
        App By
        <a
          target="_blank"
          href="https://www.linkedin.com/in/daniel-davies-35ab271bb/"
          class="text-white hover:font-bold cursor-pointer align-middle"
        >
          Daniel Davies
        </a>
        <span class="align-middle">&</span>
        <a
          target="_blank"
          href="https://www.drsamanthabrooks.com/"
          class="text-white hover:font-bold cursor-pointer align-middle"
        >
          Dr Samantha Brooks
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/curbyouradd"
          class="text-white hover:font-bold cursor-pointer"
        >
          <facebook-icon class="inline-block ml-2 align-middle" />
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import FacebookIcon from './Icons/FacebookIcon.vue';

export default {
  name: 'Footer',
  components: { FacebookIcon },
  props: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
