
import { reactive, ref } from '@vue/reactivity';
import { useRoute } from 'vue-router';
import { CharacterPicker } from '../services/CharacterPicker';
import { GameLoop } from '../services/GameLoop';
import ScoreTracker from '../services/ScoreTracker';
import InputDetector from '../services/InputDetector';
import LevelSettingMapper from '../services/LevelSettingMapper';
import ImageLoader from '../services/ImageLoader';
import AppetiteImageCollection from '../services/imageCollections/AppetiteImageCollection';
import AlcoholImageCollection from '../services/imageCollections/AlcoholImageCollection';
import AnxietyImageCollection from '../services/imageCollections/AnxietyImageCollection';
import AddictionImageCollection from '../services/imageCollections/AddictionImageCollection';
import AggressionImageCollection from '../services/imageCollections/AggressionImageCollection';
import LevelExplanation from '../components/LevelExplanation.vue';
import TimerProgressBar from '../components/TimerProgressBar.vue';
import GameLoader from '../components/GameLoader.vue';
import RestIndicator from '../components/RestIndicator.vue';
import LevelEndPanel from '../components/LevelEndPanel.vue';
import { db } from '../db';
import ImageFlasher from '../components/ImageFlasher.vue';
import LevelLockedWarning from '../components/LevelLockedWarning.vue';
import InGameLevelIndicator from '../components/InGameLevelIndicator.vue';

export default {
  name: 'Game',
  components: {
    LevelExplanation,
    TimerProgressBar,
    GameLoader,
    RestIndicator,
    LevelEndPanel,
    ImageFlasher,
    LevelLockedWarning,
    InGameLevelIndicator,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const gameStarted = ref(false);
    const gameEnded = ref(false);

    const isLevelUnlocked = ref(true);

    const displayedCharacter = ref('');

    const bgCorrect = ref(false);
    const bgIncorrect = ref(false);

    const isRestActive = ref(false);

    const backgroundImageUrl = ref('');

    const scores = reactive({
      correct: 0,
      incorrect: 0,
      missed: 0,
      percentage: 100,
      totalLetters: 0,
    });

    let pageLoaded = false;

    // score handling variables:
    let clickedThisLetter = false;
    let shouldClick = false;
    let missed = false;
    let characterIsDisplayed = false;
    // end

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const route: any = useRoute();
    const level = parseInt(route.params.level, 10);
    const { category } = route.params;

    const levelSettingMapper = new LevelSettingMapper();
    const settings = reactive(levelSettingMapper.getSettingsForLevel(level));

    // const appetiteImageLoader = new AppetiteImageCollection();
    const imageLoader = new ImageLoader();
    const imageUrls: {value: string[];} = reactive({ value: [] });
    switch (category) {
      case 'Addiction':
        imageUrls.value = AddictionImageCollection.getUrls();
        break;
      case 'Alcohol':
        imageUrls.value = AlcoholImageCollection.getUrls();
        break;
      case 'Aggression':
        imageUrls.value = AggressionImageCollection.getUrls();
        break;
      case 'Appetite':
        imageUrls.value = AppetiteImageCollection.getUrls();
        break;
      case 'Anxiety':
        imageUrls.value = AnxietyImageCollection.getUrls();
        break;
      default:
        imageUrls.value = [];
        break;
    }
    imageLoader.setImageUrls(imageUrls.value);
    imageLoader.loadImages();

    const inputDetector = new InputDetector();
    const scoreTracker = new ScoreTracker();
    const characterPicker: CharacterPicker = new CharacterPicker(
      settings.characterPickerConfig,
    );
    const gameLoop = new GameLoop(
      () => {
        // show new character
        displayedCharacter.value = characterPicker.getNextLetter();
        shouldClick = characterPicker.getIsMatch();
        missed = false;
        clickedThisLetter = false;
        characterIsDisplayed = true;
        backgroundImageUrl.value = '';
        scoreTracker.newLetter();
      },
      () => {
        // show inbetween character
        displayedCharacter.value = '+';
        if (shouldClick && !clickedThisLetter && !missed) {
          // letter missed
          // eslint-disable-next-line no-use-before-define
          handleMissedClick();
        }
        characterIsDisplayed = false;
      },
      () => {
        // flash background image
        backgroundImageUrl.value = imageLoader.getRandomUrl();
      },
      () => {
        // on flash correct end
        bgCorrect.value = false;
      },
      () => {
        // on flash incorrect end
        bgIncorrect.value = false;
      },
      () => {
        // rest start
        isRestActive.value = true;
        backgroundImageUrl.value = '';
        characterPicker.reset();
      },
      () => {
        // rest end
        isRestActive.value = false;
      },
      () => {
        // level end
        if (!gameEnded.value) {
          storeLevelCompletion();
        }
        gameEnded.value = true;
        // eslint-disable-next-line no-use-before-define
        bgCorrect.value = false;
        bgIncorrect.value = false;
        backgroundImageUrl.value = '';
      },
      settings.gameLoopConfig,
    );

    const startLevel = () => {
      gameStarted.value = true;
      gameLoop.start();
    };

    // // add a small delay to prevent game from starting immediately
    // // upon navigating to page.
    setTimeout(() => { pageLoaded = true; }, 100);

    const updateScores = () => {
      scores.correct = scoreTracker.getTotalCorrect();
      scores.incorrect = scoreTracker.getTotalIncorrect();
      scores.missed = scoreTracker.getTotalMissed();
      scores.percentage = scoreTracker.getPercentage();
      scores.totalLetters = scoreTracker.getTotalLetters();
    };

    const handleCorrectClick = () => {
      gameLoop.startFlashCorrect();
      bgCorrect.value = true;
      scoreTracker.correctInput();
      clickedThisLetter = true;
      updateScores();
    };

    const handleIncorrectClick = () => {
      gameLoop.startFlashIncorrect();
      bgIncorrect.value = true;
      scoreTracker.incorrectInput();
      clickedThisLetter = true;
      updateScores();
    };

    const handleMissedClick = () => {
      gameLoop.startFlashIncorrect();
      bgIncorrect.value = true;
      missed = true;
      scoreTracker.missedInput();
      updateScores();
    };

    const inputDetected = (): void => {
      if (imageLoader.totalImages.value !== imageLoader.totalLoaded.value) {
        return;
      }

      if (pageLoaded && !gameStarted.value && isLevelUnlocked) {
        startLevel();
        return;
      }

      if (!characterIsDisplayed || clickedThisLetter || isRestActive.value || gameEnded.value || !isLevelUnlocked || !gameStarted.value) {
        return;
      }

      // correct
      if (shouldClick && !clickedThisLetter && characterIsDisplayed) {
        handleCorrectClick();
        return;
      }

      // incorrect
      if (!shouldClick && !clickedThisLetter && characterIsDisplayed) {
        handleIncorrectClick();
      }
    };

    // call input detected whenever a touch, spacebar or click event is detected
    inputDetector.setHandler(inputDetected);

    const storeLevelCompletion = async () => {
      await db.levelCompletions.add({
        category,
        level,
        percentage: scoreTracker.getPercentage(),
        numCorrect: scoreTracker.getTotalCorrect(),
        numIncorrect: scoreTracker.getTotalIncorrect(),
        numMissed: scoreTracker.getTotalMissed(),
        numTotal: scoreTracker.getTotalLetters(),
        completedAt: Date.now(),
      });
    };

    const getLevelCompletions = async (): Promise<boolean> => {
      try {
        if (level === 0) {
          return true;
        }
        const completions = await db.levelCompletions
          .where('category')
          .equals(category)
          .filter((levelCompletion) => levelCompletion.level === level - 1)
          .filter((levelCompletion) => levelCompletion.percentage >= 80)
          .toArray();
        return completions.length > 0;
      } catch (error) {
        return false;
      }
    };

    getLevelCompletions().then((unlocked) => {
      isLevelUnlocked.value = unlocked;
    });

    return {
      displayedCharacter,
      gameStarted,
      gameEnded,
      scores,
      settings,
      bgCorrect,
      bgIncorrect,
      level,
      category,
      levelTimer: gameLoop.levelTimer,
      levelPercentage: gameLoop.levelProgressPercentage,
      backgroundImageUrl,
      imageUrls,
      totalImages: imageLoader.totalImages,
      loadedImages: imageLoader.totalLoaded,
      isRestActive,
      restTimer: gameLoop.restTimer,
      isLevelUnlocked,
    };
  },
};
