<template>
  <div
    v-if="levelNumber === 0"
    class="text-white text-2xl md:text-3xl"
  >
    Press when X is shown
  </div>
  <div
    v-else-if="interval === 1"
    class="text-white text-2xl md:text-3xl"
  >
    Press when the same letter is displayed twice in a row
  </div>
  <div
    v-else
    class="text-white text-2xl md:text-3xl"
  >
    Press when the letter shown is the same as the letter
    {{ levelNumber }} iterations previous
  </div>
</template>

<script>

export default {
  name: 'InGameLevelIndicator',
  components: {
  },
  props: {
    levelNumber: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style
    CYAHeadLogo>
</style>
