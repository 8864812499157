class AggressionImageCollection {
  public static getUrls = (): string[] => [
    '/images/subliminal/aggression/1.jpg',
    '/images/subliminal/aggression/2.jpg',
    '/images/subliminal/aggression/3.jpg',
    '/images/subliminal/aggression/4.jpg',
    '/images/subliminal/aggression/5.jpg',
    '/images/subliminal/aggression/6.jpg',
    '/images/subliminal/aggression/7.jpg',
    '/images/subliminal/aggression/8.jpg',
    '/images/subliminal/aggression/9.png',
    '/images/subliminal/aggression/10.png',
    '/images/subliminal/aggression/11.png',
    '/images/subliminal/aggression/12.png',
    '/images/subliminal/aggression/13.png',
    '/images/subliminal/aggression/14.png',
    '/images/subliminal/aggression/15.png',
    '/images/subliminal/aggression/16.png',
    '/images/subliminal/aggression/17.png',
    '/images/subliminal/aggression/18.png',
    '/images/subliminal/aggression/19.png',
    '/images/subliminal/aggression/20.png',
    '/images/subliminal/aggression/21.png',
  ]
}

export default AggressionImageCollection;
