<template>
  <div
    class="inline-block align-middle p-2 rounded-full"
    :class="'bg-' + backgroundColor"
  >
    <fast-food-icon
      v-if="icon === 'fast-food'"
      class="h-6"
    />
    <wine-icon
      v-if="icon === 'wine'"
      class="h-6"
    />
    <warning-icon
      v-if="icon === 'warning'"
      class="h-6"
    />
    <settings-icon
      v-if="icon === 'settings'"
      class="h-6"
    />
    <smoking-icon
      v-if="icon === 'smoking'"
      class="h-6"
    />
    <blank-face-icon
      v-if="icon === 'blank-face'"
      class="h-6"
    />
  </div>
</template>

<script>
import BlankFaceIcon from './BlankFaceIcon.vue';
import FastFoodIcon from './FastFoodIcon.vue';
import SettingsIcon from './SettingsIcon.vue';
import SmokingIcon from './SmokingIcon.vue';
import WarningIcon from './WarningIcon.vue';
import WineIcon from './WineIcon.vue';

export default {
  name: 'LevelCategorySelectorIcon',
  components: {
    FastFoodIcon,
    WineIcon,
    WarningIcon,
    SettingsIcon,
    SmokingIcon,
    BlankFaceIcon,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'blue-200',
    },
    icon: {
      type: String,
      default: '',
    },
  },
};
</script>

<style>
</style>
