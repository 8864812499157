<template>
  <div class="text-gray-700 text-xl mt-4 font-bold">
    Instructions:
  </div>
  <div
    v-if="fixedTarget"
    class="text-gray-700 text-xl"
  >
    Press only if X is shown
    <div>
      <span class="inline-block font-bold"> X </span>
      <check-mark-icon class="inline-block h-8 pb-1 pl-2" />
    </div>
    <div>
      <span class="inline-block font-bold"> B </span>
      <incorrect-icon class="inline-block h-8 pb-1 pl-2" />
    </div>
  </div>
  <div
    v-else-if="interval === 0"
    class="text-gray-700 text-xl"
  >
    Press when the same letter is displayed twice in a row
    <div>
      <span class="inline-block font-bold"> A A </span>
      <check-mark-icon class="inline-block h-8 pb-1 pl-2" />
    </div>
    <div>
      <span class="inline-block font-bold"> A B </span>
      <incorrect-icon class="inline-block h-8 pb-1 pl-2" />
    </div>
  </div>
  <div
    v-else
    class="text-gray-700 text-xl "
  >
    Press when the letter shown is the same as the letter
    {{ interval + 1 }} iterations previous
    <div>
      <span class="inline-block mt-2 font-bold">
        A
        <strong
          v-if="interval === 1"
          class="text-blue-500"
        >[any letter]</strong>
        <strong
          v-else
          class="text-blue-500"
        >[any {{ interval }} letters]</strong>
        A
      </span>
      <check-mark-icon class="inline-block h-8 pb-1 pl-2" />
    </div>
    <div>
      <span class="inline-block font-bold">
        A
        <strong
          v-if="interval === 1"
          class="text-blue-500"
        >[any letter]</strong>
        <strong
          v-else
          class="text-blue-500"
        >[any {{ interval }} letters]</strong>
        B
      </span>
      <incorrect-icon class="inline-block h-8 pb-1 pl-2" />
    </div>
  </div>
</template>

<script>
import CheckMarkIcon from './Icons/CheckMarkIcon.vue';
import IncorrectIcon from './Icons/IncorrectIcon.vue';

export default {
  name: 'LevelExplanation',
  components: {
    CheckMarkIcon,
    IncorrectIcon,
  },
  props: {
    fixedTarget: {
      type: String,
      default: null,
    },
    interval: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style
    CYAHeadLogo>
</style>
