import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home.vue';
import CategorySelect from '@/views/CategorySelect.vue';
import LevelHistory from '@/views/LevelHistory.vue';
import LevelSelect from '@/views/LevelSelect.vue';
import Game from '@/views/Game.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/level-history',
    name: 'LevelHistory',
    component: LevelHistory,
  },
  {
    path: '/category-select',
    name: 'CategorySelect',
    component: CategorySelect,
  },
  {
    path: '/categories/:category/level-select',
    name: 'LevelSelect',
    component: LevelSelect,
  },
  {
    path: '/categories/:category/level/:level',
    name: 'Game',
    component: Game,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes as RouteRecordRaw[],
});

export default router;
