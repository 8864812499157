import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.urls, (url, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: index,
      class: _normalizeClass(["w-full h-full absolute", {invisible: $props.targetUrl !== url}]),
      style: _normalizeStyle({
      backgroundImage: 'url(' + url + ')',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    })
    }, null, 6))
  }), 128))
}