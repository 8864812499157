<template>
  <img
    :src="require('@/assets/face_6_FILL0_wght400_GRAD0_opsz48.svg')"
    alt=""
  >
</template>

<script>

export default {
  name: 'BlankFaceIcon',
  components: {},
};
</script>

<style>
</style>
