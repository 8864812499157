<template>
  <div
    class="progress"
    :class="'border-' + backgroundColor"
  >
    <div
      class="color"
      :class="'bg-' + foregroundColor"
      :style="`width: ${progress}%`"
    />
  </div>
</template>

<script>
export default {
  name: 'LoadingBar',
  components: {},
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    backgroundColor: {
      type: String,
      default: 'blue-200',
    },
    foregroundColor: {
      type: String,
      default: 'blue-700',
    },
  },
};
</script>

<style scoped>
    .progress{
        position: relative;
        height: 11px;
        width: 100%;
        border-radius: 20px;
        border-width: 11px;
    }
    .progress .color{
        position: absolute;
        width: 0px;
        height: 10px;
        border-radius: 15px;
        bottom: -5px;
    }
</style>
