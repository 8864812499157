import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-82d9f060"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 1,
  class: "fixed w-screen h-screen border-t-2 mt-1"
}
const _hoisted_3 = {
  class: "my-1 mx-6 flex",
  style: {"align-items":"center"}
}
const _hoisted_4 = { class: "mr-2 text-white text-lg" }
const _hoisted_5 = { class: "w-screen text-center center-vertical -mt-12 md:-mt-12 px-6" }
const _hoisted_6 = {
  key: 0,
  class: "bg-white inline-block text-left p-4 rounded max-w-3xl"
}
const _hoisted_7 = { class: "text-gray-700 text-xl font-bold" }
const _hoisted_8 = { class: "text-blue-500 capitalize" }
const _hoisted_9 = {
  key: 1,
  class: "text-white text-2xl mt-4"
}
const _hoisted_10 = {
  key: 2,
  class: "text-white text-10xl uppercase"
}
const _hoisted_11 = { key: 3 }
const _hoisted_12 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_level_locked_warning = _resolveComponent("level-locked-warning")!
  const _component_game_loader = _resolveComponent("game-loader")!
  const _component_image_flasher = _resolveComponent("image-flasher")!
  const _component_timer_progress_bar = _resolveComponent("timer-progress-bar")!
  const _component_in_game_level_indicator = _resolveComponent("in-game-level-indicator")!
  const _component_level_explanation = _resolveComponent("level-explanation")!
  const _component_rest_indicator = _resolveComponent("rest-indicator")!
  const _component_level_end_panel = _resolveComponent("level-end-panel")!

  return (!$setup.isLevelUnlocked)
    ? (_openBlock(), _createBlock(_component_level_locked_warning, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($setup.totalImages !== $setup.loadedImages)
          ? (_openBlock(), _createBlock(_component_game_loader, {
              key: 0,
              "total-images": $setup.totalImages,
              "total-loaded": $setup.loadedImages
            }, null, 8, ["total-images", "total-loaded"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_image_flasher, {
                urls: $setup.imageUrls.value,
                "target-url": $setup.backgroundImageUrl
              }, null, 8, ["urls", "target-url"]),
              _createElementVNode("div", {
                class: _normalizeClass(["fixed w-screen h-screen", {
          'bg-green-500': $setup.bgCorrect,
          'bg-red-500': $setup.bgIncorrect,
        }])
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString($setup.levelTimer), 1),
                  _createVNode(_component_timer_progress_bar, {
                    progress: $setup.levelPercentage,
                    "background-color": "white",
                    "foreground-color": "blue-500",
                    class: ""
                  }, null, 8, ["progress"])
                ]),
                (!$setup.isRestActive && !$setup.gameEnded && $setup.gameStarted)
                  ? (_openBlock(), _createBlock(_component_in_game_level_indicator, {
                      key: 0,
                      class: "text-center mt-24 xl:mt-48 mx-6",
                      "level-number": $setup.level
                    }, null, 8, ["level-number"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_5, [
                  (!$setup.gameStarted)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("span", _hoisted_8, _toDisplayString($setup.category), 1),
                          _createTextVNode(" : Level " + _toDisplayString($setup.level), 1)
                        ]),
                        _createVNode(_component_level_explanation, {
                          "fixed-target": $setup.settings.characterPickerConfig.fixedTargetLetter,
                          interval: $setup.settings.characterPickerConfig.minDistanceBetweenRepeat
                        }, null, 8, ["fixed-target", "interval"])
                      ]))
                    : _createCommentVNode("", true),
                  (!$setup.gameStarted)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Click, tap the screen, or press space to start "))
                    : _createCommentVNode("", true),
                  (!$setup.isRestActive && !$setup.gameEnded)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString($setup.displayedCharacter), 1))
                    : (!$setup.gameEnded)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createVNode(_component_rest_indicator, { timer: $setup.restTimer }, null, 8, ["timer"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createVNode(_component_level_end_panel, {
                            class: "-mt-6",
                            "total-correct": $setup.scores.correct,
                            "total-false-positive": $setup.scores.incorrect,
                            "total-letters": $setup.scores.totalLetters,
                            "total-missed": $setup.scores.missed,
                            percentage: $setup.scores.percentage
                          }, null, 8, ["total-correct", "total-false-positive", "total-letters", "total-missed", "percentage"])
                        ]))
                ])
              ], 2)
            ]))
      ]))
}