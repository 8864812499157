import { Ref, ref } from 'vue';
import dayJs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { ActionList } from './ActionList';
import ShowCharacterAction from './actions/ShowCharacterAction';
import DelayAction from './actions/DelayAction';
import ShowFixationCrossAction from './actions/ShowFixationCrossAction';
import ShowBackgroundImageAction from './actions/ShowBackgroundImageAction';
import RestAction from './actions/RestAction';

dayJs.extend(duration);

interface GameLoopConfig {
  characterInterval: number,
  betweenInterval: number,
  imageInterval: number,
}

class GameLoop {
  private gameState = 'normal'; // normal, rest, ended

  private regularPlaySectionLength: number = 1000 * 60 * 5;

  private restSectionLength: number = 1000 * 60 * 1;

  private numRepeats = 3;

  // short options for debug:
  // private regularPlaySectionLength: number = 1000 * 60 * 1;

  // private restSectionLength: number = 1000 * 60 * 0.2;

  // private numRepeats = 1;

  private levelTimeTotalMilliseconds: number = (this.regularPlaySectionLength * this.numRepeats) + (this.restSectionLength * (this.numRepeats - 1))

  private levelTimeLeftMilliseconds: number = (this.regularPlaySectionLength * this.numRepeats) + (this.restSectionLength * (this.numRepeats - 1))

  public levelTimer: Ref<string> = ref(dayJs.duration(this.levelTimeTotalMilliseconds).format('mm:ss'));

  public levelProgressPercentage: Ref<number> = ref(0);

  public restStartedAt: number | null = null;

  public restTimer: Ref<number> = ref(0);

  private delta = 0;

  private lastFrameTimeMs = 0;

  private timestamp = 0;

  private normalPlayActionList: ActionList;

  private restActionList: ActionList;

  private correctFlashedAt = 0;

  private correctIsFlashing = false;

  private onFlashCorrectEnd: () => void;

  private incorrectFlashedAt = 0;

  private incorrectIsFlashing = false;

  private onFlashIncorrectEnd: () => void;

  private onLevelEnd: () => void;

  private updateState: () => void = () => {
    this.gameState = this.isRest(this.timestamp) ? 'rest' : 'normal';
    this.gameState = this.isLevelEnded() ? 'ended' : this.gameState;
  }

  constructor(
    onNewCharacter: () => void,
    onBetweenCharacters: () => void,
    onShowImage: () => void,
    onFlashCorrectEnd: () => void,
    onFlashIncorrectEnd: () => void,
    onRestStart: () => void,
    onRestEnd: () => void,
    onLevelEnd: () => void,
    config: GameLoopConfig,
  ) {
    this.onFlashCorrectEnd = onFlashCorrectEnd;
    this.onFlashIncorrectEnd = onFlashIncorrectEnd;
    this.onLevelEnd = onLevelEnd;
    this.normalPlayActionList = new ActionList(
      [
        new ShowCharacterAction(onNewCharacter),
        new DelayAction(config.characterInterval),
        new ShowFixationCrossAction(onBetweenCharacters),
        new DelayAction(config.betweenInterval),
        new ShowBackgroundImageAction(onShowImage),
        new DelayAction(config.imageInterval),
      ],
      this.updateState,
    );

    this.restActionList = new ActionList(
      [
        new RestAction(
          this.restSectionLength, // 1 minute
          onRestStart,
          onRestEnd,
        ),
      ],
      this.updateState,
    );
  }

  public start = (): void => {
    window.requestAnimationFrame(this.gameLoop);
  }

  public startFlashCorrect = (): void => {
    this.correctFlashedAt = this.timestamp;
    this.correctIsFlashing = true;
  }

  public startFlashIncorrect = (): void => {
    this.incorrectFlashedAt = this.timestamp;
    this.incorrectIsFlashing = true;
  }

  private isRest = (timestamp: number): boolean => {
    for (let i = 0; i < this.numRepeats + 0; i++) {
      if (timestamp > this.regularPlaySectionLength + (this.regularPlaySectionLength * i) + (this.restSectionLength * i)
        && timestamp < (this.regularPlaySectionLength * (i + 1)) + (this.restSectionLength * (i + 1))
      ) {
        return true;
      }
    }
    return false;
  }

  private isLevelEnded = (): boolean => this.timestamp > this.levelTimeTotalMilliseconds

  private startTimestamp?: number;

  private gameLoop = (timestampWithoutOffset: number) => {
    // level timer
    if (this.startTimestamp === undefined) {
      this.startTimestamp = timestampWithoutOffset;
    }
    this.timestamp = timestampWithoutOffset - this.startTimestamp;
    this.levelTimeLeftMilliseconds = this.levelTimeTotalMilliseconds - this.timestamp;
    this.levelTimer.value = dayJs.duration(this.levelTimeLeftMilliseconds).format('mm:ss');
    this.levelProgressPercentage.value = Math.floor(1000 - (this.levelTimeLeftMilliseconds / this.levelTimeTotalMilliseconds) * 1000) / 10;
    // end

    // timeline
    this.delta = this.timestamp - this.lastFrameTimeMs;

    if (this.gameState === 'rest') {
      if (!this.restStartedAt) {
        this.restStartedAt = this.timestamp;
      }
      this.restTimer.value = dayJs.duration(this.timestamp - this.restStartedAt, 'milliseconds').seconds();
      this.restActionList.update(this.delta);
    } else if (this.gameState === 'normal') {
      this.normalPlayActionList.update(this.delta);
      this.restStartedAt = null;
    }
    // end

    // flashes are controlled by user input so not part of timeline
    if (this.correctFlashedAt + 200 < this.timestamp && this.correctIsFlashing) {
      this.onFlashCorrectEnd();
      this.correctIsFlashing = false;
    }

    if (this.incorrectFlashedAt + 200 < this.timestamp && this.incorrectIsFlashing) {
      this.onFlashIncorrectEnd();
      this.incorrectIsFlashing = false;
    }
    // end

    this.lastFrameTimeMs = this.timestamp;

    if (this.gameState === 'ended') {
      this.onLevelEnd();
    } else {
      requestAnimationFrame(this.gameLoop);
    }
  }
}

export { GameLoop };
export type { GameLoopConfig };
