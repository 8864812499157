
import { ref } from 'vue';
import CYAHeadLogo from '../components/Logos/CYAHeadLogo.vue';

export default {
  name: 'Home',
  components: {
    CYAHeadLogo,
  },
  setup() {
    const showVersion = ref(false);
    const version = '1.0.4';
    return { showVersion, version };
  },
};
