

export default {
  name: 'RestIndicator',
  components: {},
  props: {
    timer: {
      type: Number,
      default: 60,
    },
  },
};
