class AlcoholImageCollection {
  public static getUrls = (): string[] => [
    '/images/subliminal/alcohol/ambitious-creative-co-rick-barrett-UyF3Tal_KXw-unsplash.jpg',
    '/images/subliminal/alcohol/andreas-m-Ug8GLlQT1oE-unsplash.jpg',
    '/images/subliminal/alcohol/andres-haro-dominguez-wHpu6pYu-9g-unsplash.jpg',
    '/images/subliminal/alcohol/brian-jones-YBlcnXfv9OM-unsplash.jpg',
    '/images/subliminal/alcohol/dayso-295_vM4QO4c-unsplash.jpg',
    '/images/subliminal/alcohol/edward-howell-55bQ7g3ZlQU-unsplash.jpg',
    '/images/subliminal/alcohol/giancarlo-duarte-w2C731GlwKk-unsplash.jpg',
    '/images/subliminal/alcohol/gleb-albovsky-LQ1Veo0EaqI-unsplash.jpg',
    '/images/subliminal/alcohol/hush-naidoo-jade-photography-vdstHslc9VQ-unsplash.jpg',
    '/images/subliminal/alcohol/jacek-dylag-JwWKV2gCPkE-unsplash.jpg',
    '/images/subliminal/alcohol/johanne-kristensen-ohoOQxgvB0w-unsplash.jpg',
    '/images/subliminal/alcohol/julia-nastogadka-AMwYylKQsUc-unsplash.jpg',
    '/images/subliminal/alcohol/kelsey-chance-tAH2cA_BL5g-unsplash.jpg',
    '/images/subliminal/alcohol/kelsey-knight-udj2tD3WKsY-unsplash.jpg',
    '/images/subliminal/alcohol/kevin-kelly-PPneSBqfCCU-unsplash.jpg',
    '/images/subliminal/alcohol/kobby-mendez-xBFTjrMIC0c-unsplash.jpg',
    '/images/subliminal/alcohol/mae-mu-GmoLpEYLpe8-unsplash.jpg',
    '/images/subliminal/alcohol/mae-mu-YNMjGIPgD_c-unsplash.jpg',
    '/images/subliminal/alcohol/ram-ho-l5ooCJlUgvU-unsplash.jpg',
    '/images/subliminal/alcohol/yasin-aribuga-daMT4OR3Eac-unsplash.jpg',
  ]
}

export default AlcoholImageCollection;
