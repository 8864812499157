
export default {
  name: 'ImageFlasher',
  props: {
    urls: {
      type: Array,
      default(): Array<string> { return []; },
    },
    targetUrl: {
      type: String,
      default: null,
    },
  },
};
