// db.ts
import Dexie, { Table } from 'dexie';

export interface LevelCompletion {
  id?: number;
  category: string;
  level: number;
  percentage: number;
  numCorrect: number;
  numIncorrect: number;
  numMissed: number;
  numTotal: number;
  completedAt: number;
}

export class CyeDb extends Dexie {
  levelCompletions!: Table<LevelCompletion>;

  constructor() {
    super('CYE');
    this.version(1).stores({
      levelCompletions: '++id, category, level', // Primary key and indexed props
    });
  }
}

export const db = new CyeDb();
