import { Ref, ref } from 'vue';

class ImageLoader {
  public totalImages: Ref<number> = ref(0);

  public totalLoaded: Ref<number> = ref(0);

  private imageUrls: string[]= [];

  private nonLoadedImageUrls: string[] = [];

  public setImageUrls = (urls: string[]): void => {
    this.totalImages.value = urls.length;
    this.imageUrls = urls;
    this.nonLoadedImageUrls = urls;
  }

  public loadImages = (): void => {
    this.imageUrls.forEach((url) => this.loadImage(url));
  }

  public getRandomUrl = (): string => this.imageUrls[Math.floor(Math.random() * this.imageUrls.length)]

  private loadImage = (url: string): void => {
    const img = new Image();
    img.src = window.location.origin + url;

    img.onload = async () => {
      this.totalLoaded.value++;

      // uncomment following two lines to work out which image(s) are failing to load
      // this.nonLoadedImageUrls.splice(this.nonLoadedImageUrls.indexOf(url), 1);
      // console.log(this.nonLoadedImageUrls);
    };
  }
}

export default ImageLoader;
