import { Action } from '../ActionList';

class ShowFixationCrossAction implements Action {
  isFinished = false;

  handler: () => void;

  constructor(showMethod: () => void) {
    this.handler = showMethod;
  }

  update = (): void => {
    if (!this.isFinished) {
      this.isFinished = true;
      this.handler();
    }
  }

  reset = (): void => {
    this.isFinished = false;
  }
}

export default ShowFixationCrossAction;
