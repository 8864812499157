// This class utilizes an action List approach to timeline
// the various actions that need to happen in order
//
// see https://eliasdaler.github.io/how-to-implement-action-sequences-and-cutscenes/
// for an explanation around how this works
interface Action {
  isFinished: boolean,
  update: (dt: number) => void,
  reset: () => void
}

class ActionList {
  actions: Action[] = [];

  currentActionIndex = 0;

  updateState: () => void

  constructor(actions: Action[], updateState: () => void) {
    this.actions = actions;
    this.updateState = updateState;
  }

  update = (dt: number): void => {
    const currentAction = this.actions[this.currentActionIndex];
    if (!currentAction.isFinished) {
      currentAction.update(dt);
      if (currentAction.isFinished) {
        this.updateState();
        this.currentActionIndex = this.currentActionIndex === this.actions.length - 1 ? 0 : this.currentActionIndex + 1;
        currentAction.reset();
      }
    }
  }
}

export { ActionList };
export type { Action };
