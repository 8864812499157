<template>
  <router-link
    class="bg-white rounded py-4 px-4 relative hover:bg-gray-100 cursor-pointer"
    :class="locked ? 'cursor-not-allowed' : 'cursor-pointer'"
    :to="locked ? '' : './level/' + levelKey"
  >
    <div
      class="text-xl font-bold md:flex md:justify-between text-center md:text-left"
      :class="locked ? 'text-blue-300' : 'text-blue-700'"
    >
      {{ levelName }}
    </div>
    <div
      class="text-xl md:flex md:justify-between text-center md:text-left"
      :class="locked ? 'text-blue-300' : 'text-blue-700'"
    >
      Best: {{ locked ? 0 : bestScore }}%
    </div>
    <div
      v-if="!locked"
      class="text-gray-700 text-xl mt-4 text-center md:text-left"
    >
      Click To Play
    </div>
    <div
      v-else
      class="text-gray-500 text-xl mt-4 text-center md:text-left"
    >
      <div
        class="inline-block align-middle"
      >
        Locked
      </div>
      <lock-icon class="inline-block h-6" />
    </div>
  </router-link>
</template>

<script>
import LockIcon from './Icons/LockIcon.vue';

export default {
  name: 'LevelSelector',
  components: {
    LockIcon,
  },
  props: {
    levelName: {
      type: String,
      default: '',
    },
    bestScore: {
      type: Number,
      default: 0,
    },
    locked: {
      type: Boolean,
      default: true,
    },
    levelKey: {
      type: String,
      default: '',
    },
  },
};
</script>

<style
    CYAHeadLogo>
</style>
