
import { reactive } from 'vue';
import { db, LevelCompletion } from '@/db';
import LevelCategorySelector from '../components/LevelCategorySelector.vue';

export default {
  name: 'LevelSelect',
  components: { LevelCategorySelector },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const levelCompletions = reactive({
      Standard: {
        highestLevel: 0,
        percentage: 0,
      },
      Appetite: {
        highestLevel: 0,
        percentage: 0,
      },
      Anxiety: {
        highestLevel: 0,
        percentage: 0,
      },
      Alcohol: {
        highestLevel: 0,
        percentage: 0,
      },
      Aggression: {
        highestLevel: 0,
        percentage: 0,
      },
      Addiction: {
        highestLevel: 0,
        percentage: 0,
      },
    });

    const getLevelCompletions = async (): Promise<LevelCompletion[] | undefined> => {
      try {
        return await db.levelCompletions
          .reverse()
          .sortBy('level');
      } catch (error) {
        return undefined;
      }
    };

    getLevelCompletions().then((completions): void => {
      if (!completions || completions.length < 1) {
        return;
      }
      completions.forEach((completion: LevelCompletion) => {
        if (
          completion.category !== 'Standard'
          && completion.category !== 'Appetite'
          && completion.category !== 'Anxiety'
          && completion.category !== 'Alcohol'
          && completion.category !== 'Aggression'
          && completion.category !== 'Addiction'
        ) {
          return;
        }

        if (levelCompletions[completion.category].highestLevel < completion.level) {
          levelCompletions[completion.category].highestLevel = completion.level;
        }

        if (
          levelCompletions[completion.category].highestLevel === completion.level
          && levelCompletions[completion.category].percentage < completion.percentage
        ) {
          levelCompletions[completion.category].percentage = completion.percentage;
        }
      });
    });

    return {
      levelCompletions,
    };
  },
};
